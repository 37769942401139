.App {
  text-align: center;
  background-color: #0D0E11;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

.opal_live_heading {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 24;
  top: 24;

}

.flash_btn {
  width: 96px;
  height: 96px;
  border-radius: 50px;
  border: none;
  background-color: #7f37da;
  margin: 16px;
}

.video_frame {
  margin-top: 16px;
  background-color: #000000;
  border-radius: 12px;
  height: 55vh;
}

.video_controls {
  margin-top: 38px;
}

.current_price {
  background-color: #51C53E;
  color: white;
  width: 10vw;
  height: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 600;
  border-radius: 12px;
  margin: auto;
}

.chat_frame {
  background-color: #ffffff;
  border-radius: 8px;
  height: 80vh;
  padding: 24px;
}

.grid-container {
  display: flex;
  height: auto;
}

@media screen and (min-width: 1400px) {
  .grid-container {
    width: 80vw;
    margin: auto;
  }
}

.left-column {
  width: 65%;
  height: 100%;
  background-color: transparent;
  padding-top: 48px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
}

.right-column {
  width: 35%;
  background-color: transparent;
  padding: 48px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Styles for the modal overlay */
.ReactModal__Overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgba(0, 0, 0, 0.5) !important;
display: flex;
justify-content: center;
align-items: center;
}

/* Styles for the modal content */
.ReactModal__Content {
position: relative;
top: auto;
left: auto;
right: auto;
bottom: auto;
margin: 0 auto;
max-width: 500px;
width: 100%;
height: 60vh;
inset: unset;
background-color: #fff;
border-radius: 4px;
padding: 20px;
box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
overflow: auto;
}

/* Styles for the modal title */
.ReactModal__Content h2 {
margin-top: 0;
}

/* Styles for the modal items */
.ReactModal__Content ul {
list-style: none;
margin: 0;
padding: 0;
}

/* Styles for the modal close button */
.ReactModal__Content button {
background-color: transparent;
border: none;
cursor: pointer;
}
