.t-action-btn{
    display: flex;
    button{
        margin: 0 3px;
        padding: 4px 8px;
        font-size: 14px;
    }
}

.badge {
    font-size: .9em !important;
}
.del_cat_desc {
    
    max-width: 400px !important;
    
    overflow: auto !important;
}
.user_detail_img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    box-shadow: 0px 1px 10px rgba(255, 255, 255,0.1);
    overflow: hidden !important;
}
.user_detail_img_container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: inherit !important;
}
.item_detail_img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    box-shadow: 0px 1px 10px rgba(255, 255, 255,0.1);
    overflow: hidden !important;
    padding: 10px;
}
.item_detail_img_container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.user_view_label {
    font-weight: 700;
}